// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const showerSlides = [
  { 
    src: '/rscAssets/SOWAssets/ShowerTile/showerTile1.webp', 
    type: 'image', 
    alt: 'finished shower tile install remodel'
  },
  { 
    src: '/rscAssets/SOWAssets/ShowerTile/ShowerTileWork.mp4', 
    type: 'video', 
    alt: 'shower Remodel and shower tile install Video'
  },
  ...Array.from({ length: 27 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/ShowerTile/showerTile${i + 2}.webp`, 
    type: 'image',
    alt: `finished shower tile install remodel ${i + 1}`
  }))
];

const showerText = [
  "Our shower remodels provide the perfect combination of luxury and functionality. From sleek designs to high-quality materials, we ensure that your new shower space meets all your needs and exceeds your expectations. Our expert team is dedicated to creating a space that not only looks stunning but also stands the test of time with durable and innovative solutions.",
  "With years of experience, our team delivers professional and reliable services, transforming your bathroom into a relaxing retreat. Let us help you create a space that is both beautiful and practical. We specialize in various styles, from modern minimalist to classic elegance, ensuring we can bring your vision to life.",
  "Each shower remodel starts with a thorough consultation, where we listen to your preferences and requirements. Our designers then craft a custom plan that aligns with your lifestyle and budget. From selecting the right materials to executing flawless installations, we handle every aspect with precision and care.",
  "Shower tiling is one of our core specialties. We offer a wide range of tile options, including ceramic, porcelain, glass, and natural stone. Whether you prefer a sleek, seamless look or a more intricate mosaic design, our skilled craftsmen ensure meticulous attention to detail, resulting in a flawless finish.",
  "We believe that the details make a difference. That's why we pay close attention to elements like grout color, tile layout, and edging to enhance the overall aesthetic of your shower. Our tiling services are not only about beauty but also about ensuring water resistance and ease of maintenance.",
  "Beyond aesthetics, our shower remodels are designed with functionality in mind. We incorporate features such as built-in shelves, benches, and custom shower niches to maximize storage and convenience. Our goal is to create a shower that not only looks amazing but also makes your daily routine more enjoyable.",
  "Safety is a top priority in our remodels. We offer options for slip-resistant tiles and grab bars to ensure your shower is safe for everyone, especially for children and elderly family members. Our installations comply with the highest standards, providing peace of mind for you and your loved ones.",
  "From initial design to final installation, we are committed to exceeding your expectations at every step. Our customer satisfaction is our top priority, and we strive to deliver a shower remodel that enhances your home's value and your quality of life. Let us help you transform your bathroom into a haven of relaxation and style."
];


const ShowerCarousel = () => {
  return <GenericCarousel slides={showerSlides} title="Shower Remodeling and Shower Tiling" text={showerText} />;
};

export default ShowerCarousel;
