import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import './GenericCarousel.css'; // Import your new CSS file

const GenericCarousel = ({ slides, title, text }) => {
  
  const handleVideoPlay = (event) => {
    event.target.poster = ''; // Remove the poster when the video starts playing
  };

  return (
    <div className="sow-content">
      <section className="generic-carousel">
        <h2>{title}</h2>
        <Carousel 
          infiniteLoop 
          showIndicators={false}
          showThumbs={false} // Disable thumbnail navigation
        >
          {slides.map((slide, index) => (
            <div key={index} className="carousel-slide">
              {slide.type === 'video' ? (
                <video
                  src={slide.src}
                  muted
                  controls
                  autoPlay
                  loop
                  playsInline
                  disablePictureInPicture
                  controlsList="nodownload noremoteplayback noaudio"
                  onPlay={handleVideoPlay} // Remove the poster when the video starts playing
                />
              ) : (
                <img src={slide.src} alt={slide.alt} />
              )}
            </div>
          ))}
        </Carousel>
        <div className="generic-carousel-text">
          {text.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </section>
    </div>
  );
};

export default GenericCarousel;
