// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const patioSlides = [
  {
    src: '/rscAssets/SOWAssets/Patio/patioAddition1.webp', 
    type: 'image', 
    alt: 'patio roof addition with white columns image'
  },
  { 
    src: '/rscAssets/SOWAssets/Patio/patioStoneWork.mp4', 
    type: 'video', 
    alt: 'patio white stone install video',
    loading: "lazy"  
  },
  {
    src: '/rscAssets/SOWAssets/Patio/patioAddition.mp4', 
    type: 'video', 
    alt: 'patio addition with roof video'
  },
  ...Array.from({ length: 4 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Patio/patioWork${i + 1}.webp`, 
    type: 'image',
    alt: `concrete porch steps install with smooth finish image ${i + 1}`
  }))
];

const patioText = [
  "Enhance your outdoor living space with our custom patio additions and stone work. Whether you're looking to create a relaxing retreat or an entertainment area, we offer a variety of options to suit your style and needs. From cozy fire pits to elegant stone pathways, our expert craftsmanship ensures that your patio becomes a beautiful extension of your home.",
  "Our patio addition services begin with a detailed consultation to understand your vision and requirements. We design and construct patios that seamlessly integrate with your landscape, providing a functional and inviting space for gatherings, relaxation, and outdoor dining. Our team uses high-quality materials and innovative techniques to create durable and stylish patios.",
  "We offer a range of patio styles, including traditional paver patios, natural stone patios, and stamped concrete designs. Each option brings unique charm and character to your outdoor space. Our designers work with you to select the best materials and layout, ensuring that your new patio complements your home's architecture and enhances its curb appeal.",
  "Stone work is a timeless and versatile option for enhancing your outdoor spaces. We specialize in the installation of various stone features, including retaining walls, garden borders, walkways, and steps. Our skilled craftsmen use natural and manufactured stone to create stunning and functional landscape elements that add value and beauty to your property.",
  "Fire pits and outdoor fireplaces are popular additions to patios, providing warmth and ambiance for evening gatherings. We design and install custom fire features that match your aesthetic preferences and functional needs. From rustic stone fire pits to sleek, modern fireplaces, we create focal points that enhance your outdoor living experience.",
  "Outdoor kitchens are another excellent addition to patios, allowing you to cook and entertain in your outdoor space. We offer custom designs that include built-in grills, countertops, sinks, and storage, ensuring a fully equipped and stylish outdoor kitchen. Our installations are built to withstand the elements and provide long-lasting performance.",
  "In addition to patios and stone work, we offer a range of landscaping services to complete your outdoor transformation. This includes planting, irrigation, lighting, and more. Our holistic approach ensures that every element of your outdoor space works together to create a cohesive and inviting environment.",
  "Durability and quality are at the forefront of our patio and stone work services. We use high-grade materials and proven construction techniques to ensure that your new additions are built to last. Our attention to detail and commitment to excellence means you can trust us to deliver results that exceed your expectations.",
  "Customer satisfaction is our top priority. We work closely with you throughout the project, keeping you informed and involved in every decision. Our team is dedicated to providing exceptional service, clear communication, and reliable results. Let us help you create the outdoor space of your dreams with our expert patio additions and stone work services."
];


const PatioCarousel = () => {
  return <GenericCarousel slides={patioSlides} title="Patio Additions and Stone Work" text={patioText} />;
};

export default PatioCarousel;
