// src/mwcComponents/ImageCarousel.js
import React, { useState } from 'react';
import './ImageCarousel.css';
import { useNavigate } from 'react-router-dom';

const ImageCarousel = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    { 
      src: '/rscAssets/HomeAssets/DeckAddition.jpg', 
      alt: 'new cedar deck build', 
      label: 'Decks & Sheds',
      description: 'We build strong decks that will last a long time. Click view more to learn about our deck projects.'
    },
    { 
      src: '/rscAssets/HomeAssets/RoomAddition.jpg', 
      alt: 'new home addition on side of house', 
      label: 'Room Additions & Renovations',
      description: 'Expand your living space with our expertly crafted room additions. Click view more to learn about our room addition projects.'
    },
    { 
      src: '/rscAssets/HomeAssets/BrickWork.jpg', 
      alt: 'white stone walkway', 
      label: 'Stone & Brick Work',
      description: 'Our brickwork services provide durable and aesthetic solutions for your construction needs. Click view more to explore our brickwork projects.'
    },
    { 
      src: '/rscAssets/HomeAssets/BathroomRemodel.jpg', 
      alt: 'white bathroom remodel with big tub and window', 
      label: 'Bathroom & Kitchen Remodels',
      description: 'Transform your bathroom into a luxurious space. Click view more to learn about our bathroom remodels.'
    },
    { 
      src: '/rscAssets/HomeAssets/DryWallAddition.jpg', 
      alt: 'drywall mud and taping', 
      label: 'Drywall & Plastering',
      description: 'We offer professional drywall services to ensure a smooth and flawless finish. Click view more to learn about our drywall projects.'
    },
    { 
      src: '/rscAssets/HomeAssets/PaintAddition.jpg', 
      alt: 'white trim painting with prep work', 
      label: 'Painting',
      description: 'Enhance the beauty of your space with our expert painting services. Click view more to see our painting projects.'
    },
    { 
      src: '/rscAssets/HomeAssets/TileAddition.jpg', 
      alt: 'alternate color tile install', 
      label: 'Tile Installs',
      description: 'Our tile installation services provide a durable and stylish finish for your floors and walls. Click view more to learn more about our tile projects.'
    },
    { 
      src: '/rscAssets/HomeAssets/HardwoodAddition.jpg', 
      alt: 'hardwood flooring install with underlayment', 
      label: 'Hardwood Flooring Installs',
      description: 'Enhance your home with our expert hardwood flooring installation services. Click view more to learn about our hardwood flooring projects.'
    },    
    { 
      src: '/rscAssets/HomeAssets/FramingAddition.jpg', 
      alt: 'shed framing', 
      label: 'Rough Framing',
      description: 'Expert framing for robust structures. Click view more to learn about our framing projects.'
    }
    
  ];

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const goToSOWPage = () => {
    navigate('/see-our-work');
    window.scrollTo(0, 0);
  }

  return (
    <div className="carousel-container">
      <button className="carousel-button prev" onClick={prevSlide}>‹</button>
      <div className="carousel-slide">
        <img src={slides[currentSlide].src} alt={slides[currentSlide].alt} loading="lazy"/>
        <div className="carousel-label">
          <h1>{slides[currentSlide].label}</h1>
          <p className="carousel-description">{slides[currentSlide].description}</p>
          <button className="view-more-button" onClick={goToSOWPage}>See Our Work</button>
        </div>
      </div>
      <button className="carousel-button next" onClick={nextSlide}>›</button>
      <div className="carousel-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${currentSlide === index ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
