import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ReactGA from 'react-ga4'; // Make sure you import ReactGA

import './EstimateForm.css';

const EstimateForm = forwardRef((props, ref) => {
  const { control, handleSubmit: handleFormSubmit, formState: { errors }, register, reset } = useForm({
    defaultValues: {
      selectedOptions: [],
      message: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      street: '',
      city: '',
      zip: '',
    },
  });
  const [showOther, setShowOther] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useImperativeHandle(ref, () => ({
    resetForm: () => reset()
  }));

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch('https://rstoneconstructsapi.onrender.com/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Track form submission event after successful submission
      ReactGA.send({
        hitType: 'event',
        eventCategory: 'Form Submission',
        eventAction: 'Estimate Form Submitted',
        eventLabel: 'Estimate Page' // Optional: Specify the page or form name
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Form submitted:', result);
        reset();
        setFormSubmitted(true);
      } else {
        console.error('Error submitting form:', result);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = (value) => {
    setShowOther(value.includes('Other'));
  };

  return (
    <div className="estimate-container mt-5">
      {formSubmitted ? (
        <div className="alert alert-success">
          <h4>Your form has been submitted!</h4>
          <div className="button-group">
            <button className="btn btn-primary" onClick={() => setFormSubmitted(false)}>Submit Another Response</button>
            <a href="/" className="btn btn-secondary">View Homepage</a>
          </div>
        </div>
      ) : (
        <form onSubmit={handleFormSubmit(onSubmit)}>
          <h1>Request an Estimate</h1>
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">First Name <span className="required-label">(Required)</span></label>
            <input
              {...register('firstName', { required: 'First name is required' })}
              className="form-control"
            />
            {errors.firstName && <span className="text-danger">{errors.firstName.message}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">Last Name <span className="required-label">(Required)</span></label>
            <input
              {...register('lastName', { required: 'Last name is required' })}
              className="form-control"
            />
            {errors.lastName && <span className="text-danger">{errors.lastName.message}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone <span className="required-label">(Required)</span>
            </label>
            <Controller
              name="phone"
              control={control}
              rules={{
                required: 'Phone number is required',
                pattern: {
                  value: /^\d{10}$/,
                  message: 'Phone number must be 10 digits'
                }
              }}
              render={({ field }) => (
                <input
                  type="text"
                  className="form-control"
                  placeholder="1231231234"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
            {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email <span className="required-label">(Required)</span></label>
            <input
              {...register('email', { required: 'Email is required' })}
              className="form-control"
            />
            {errors.email && <span className="text-danger">{errors.email.message}</span>}
          </div>

          <div className="mb-3">
            <label htmlFor="street" className="form-label">Street <span className="required-label">(Required)</span></label>
            <input
              {...register('street', { required: 'Street is required' })}
              className="form-control"
            />
            {errors.street && <span className="text-danger">{errors.street.message}</span>}
          </div>

          <div className="d-flex mb-3">
            <div className="flex-fill me-2">
              <label htmlFor="city" className="form-label">City <span className="required-label">(Required)</span></label>
              <input
                {...register('city', { required: 'City is required' })}
                className="form-control"
              />
              {errors.city && <span className="text-danger">{errors.city.message}</span>}
            </div>

            <div className="flex-fill ms-2">
              <label htmlFor="zip" className="form-label">Zip <span className="required-label">(Required)</span></label>
              <input
                {...register('zip', { required: 'Zip code is required' })}
                className="form-control"
              />
              {errors.zip && <span className="text-danger">{errors.zip.message}</span>}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Contract Work <span className="required-label">(Required)</span></label>
            <Controller
              name="selectedOptions"
              control={control}
              render={({ field }) => (
                <div>
                  {[
                    'Hardwood Flooring Install',
                    'Bathroom Remodeling',
                    'Kitchen Remodeling', 
                    'Mudding/Taping',
                    'Room Additions',
                    'Window Install',
                    'Drop Ceilings',
                    'Door Install',
                    'Stone Work', 
                    'Brick Work',
                    'Fireplaces', 
                    'Painting',
                    'Framing', 
                    'Decking', 
                    'Drywall',
                    'Tiling',
                    'Other'].map(option => (
                    <div className="form-check" key={option}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        {...field}
                        value={option}
                        checked={field.value.includes(option)}
                        onChange={(e) => {
                          const newValue = [...field.value];
                          if (e.target.checked) {
                            newValue.push(option);
                          } else {
                            newValue.splice(newValue.indexOf(option), 1);
                          }
                          field.onChange(newValue);
                          handleCheckboxChange(newValue);
                        }}
                      />
                      <label className="form-check-label">{option}</label>
                    </div>
                  ))}
                </div>
              )}
            />
            {errors.selectedOptions && <span className="text-danger">{errors.selectedOptions.message}</span>}
            
            {showOther && (
              <div className="mb-3 mt-2">
                <label htmlFor="other" className="form-label">Please specify <span className="required-label">(Required)</span></label>
                <input
                  {...register('other', { required: showOther && 'Please specify other option' })}
                  className="form-control"
                />
                {errors.other && <span className="text-danger">{errors.other.message}</span>}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="message" className="form-label">Please provide details on what you are wanting to do <span className="required-label">(Required)</span></label>
            <textarea
              {...register('message', { required: 'Message is required' })}
              className="form-control"
            />
            {errors.message && <span className="text-danger">{errors.message.message}</span>}
          </div>

          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}
    </div>
  );
});

export default EstimateForm;
