import React, { useState, useRef, useEffect, useContext } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import { ModalContext } from './ModelContext'; // Import ModalContext
import { Link } from 'react-router-dom';

const Navbar = ({ hideContact, hideSeeOurWork, hideQuote }) => {
  const { openModal } = useContext(ModalContext); // Use modal context
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const [navbarClass, setNavbarClass] = useState('navbar-light'); // Default class

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    const triggerHeight = 300; // Adjust based on where your bright section starts
    if (window.scrollY > triggerHeight) {
      setNavbarClass('navbar-dark');
    } else {
      setNavbarClass('navbar-light');
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar ${navbarClass}`}>
      <div className="navbar-wrapper">
        <div className="navbar-content">
          <div className="logo">
            <a href="/">
              <img src="/rscAssets/HomeAssets/RSCLogo.svg" alt="RavenStone Construction LLC" />
            </a>
          </div>
          <div className="quote-container">
          </div>
          <div className="menu-icon" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={`nav-links ${isOpen ? 'nav-active' : ''}`} ref={menuRef}>
            {!hideQuote && (
              <Link to="/estimate-form" onClick={closeMenu}>Free Estimates</Link>
            )}
            {!hideSeeOurWork && (
              <Link to="/see-our-work" onClick={closeMenu}>See Our Work</Link>
            )}
            {!hideContact && (
              <li><a href="#contact-us" onClick={() => { closeMenu(); openModal(); }}>Contact Us</a></li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
