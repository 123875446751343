import React, { useState, useEffect } from 'react';
import './SeeOurWorkPage.css'; // Import your CSS file for styling
import Footer from '../rscComponents/common/Footer';
import ButtonLinks from '../rscComponents/SOWComponents/SOWButtonLinks';
import ShowerCarousel from '../rscComponents/SOWComponents/ShowerCarousel'; // Import the new ShowerCarousel component
import RoomRenAndOtherCarousel from '../rscComponents/SOWComponents/RoomRenOtherCarousel'; // Import the new ShowerCarousel component
import PatioCarousel from '../rscComponents/SOWComponents/PatioCarousel';
import KitchenCarousel from '../rscComponents/SOWComponents/KitchenCarousel';
import ExteriorCarousel from '../rscComponents/SOWComponents/ExteriorCarousel';
import BasementCarousel from '../rscComponents/SOWComponents/BasementCarousel';
import FlooringAndTileCarousel from '../rscComponents/SOWComponents/FlooringAndTileCarousel';
import FramingCarousel from '../rscComponents/SOWComponents/FramingCarousel';
import ShedCarousel from '../rscComponents/SOWComponents/ShedCarousel';

const SeeOurWorkPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="see-our-work-page">
      <div className="button-links-container">
        <ButtonLinks />
      </div>
      <section id="patio-section">
        <div className="carousel-section">
          <PatioCarousel />
        </div>
      </section>
      <section id="flooring-tile-section">
        <div className="carousel-section">
          <FlooringAndTileCarousel />
        </div>
      </section>
      <section id="shower-tile-section">
        <div className="carousel-section">
          <ShowerCarousel /> {/* Use the ShowerCarousel component here */}
        </div>
      </section>
      <section id="room-ren-other-section">
        <div className="carousel-section">
          <RoomRenAndOtherCarousel />
        </div>
      </section>
      
      <section id="kitchen-section">
        <div className="carousel-section">
          <KitchenCarousel />
        </div>
      </section>
      <section id="exterior-section">
        <div className="carousel-section">
          <ExteriorCarousel />
        </div>
      </section>
      <section id="basement-section">
        <div className="carousel-section">
          <BasementCarousel />
        </div>
      </section>
      <section id="framing-section">
        <div className="carousel-section">
          <FramingCarousel />
        </div>
      </section>
      <section id="shed-section">
        <div className="carousel-section">
          <ShedCarousel />
        </div>
      </section>

      <Footer customFooterClass="seeOurPage-footer"/>
      {isVisible && (
        <div className="scroll-to-top" onClick={scrollToTop}>
          ↑ Top
        </div>
      )}
    </div>
  );
};

export default SeeOurWorkPage;
