// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const kitchenSlides = [
  ...Array.from({ length: 2 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Kitchen/firePlace${i + 1}.webp`, 
    type: 'image',
    alt: `red brick and stone work fireplace image ${i + 1}`,
    loading: "lazy"  
  })),
  { 
    src: '/rscAssets/SOWAssets/Kitchen/KitchenRemodelFire2.mp4', 
    type: 'video', 
    alt: 'kitchen backsplash remodel video with fireplace remodel video'
  },
  {
    src: '/rscAssets/SOWAssets/Kitchen/KitchenAddition.mp4', 
    type: 'video', 
    alt: 'kitchen addition with cabinet hanging video'
  },
  ...Array.from({ length: 3 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Kitchen/kitchenWork${i + 1}.webp`, 
    type: 'image',
    alt: `kitchen dark blue cabinets remodel with new wooden stove hood vent image ${i + 1}`
  }))
  
];

const kitchenText = [
  "Our kitchen additions and remodels are designed to transform your culinary space into the heart of your home. Whether you're expanding your kitchen's footprint or updating its style and functionality, we provide comprehensive solutions tailored to your needs. From open-concept designs to state-of-the-art appliances, our team ensures that every detail is meticulously crafted to enhance your cooking and dining experience.",
  "Every kitchen project begins with a thorough consultation to understand your vision and requirements. We work closely with you to develop a design that maximizes space, improves workflow, and reflects your personal style. Our custom kitchen solutions include cabinetry, countertops, backsplashes, lighting, and more, all selected to create a cohesive and stunning look.",
  "Our skilled professionals handle every aspect of the remodel or addition, from demolition to final installation. We use high-quality materials and the latest techniques to ensure a durable and beautiful finish. Our goal is to create a kitchen that is not only aesthetically pleasing but also highly functional and suited to your lifestyle.",
  "Adding a kitchen island can greatly increase your workspace and storage, while also providing a focal point for gatherings. We design and install custom kitchen islands that include features like built-in sinks, stovetops, and seating areas. These additions enhance the utility and social appeal of your kitchen.",
  "Our fireplace installations add warmth and charm to any room in your home. Whether you're looking for a traditional wood-burning fireplace, a modern gas fireplace, or an electric fireplace for convenience, we offer a variety of options to suit your style and needs. Each installation is handled with precision to ensure safety and efficiency.",
  "Fireplaces are not only functional but also a beautiful design element. We offer custom mantels, surrounds, and hearths to complement your home's décor. Our team can create everything from rustic stone fireplaces to sleek, contemporary designs, enhancing the ambiance and value of your home.",
  "In addition to new installations, we also provide fireplace renovation services. If you have an existing fireplace that needs an upgrade or repair, we can help restore its beauty and functionality. Our services include refacing, updating inserts, and improving ventilation to ensure your fireplace operates efficiently and safely.",
  "Beyond kitchens and fireplaces, we offer a range of additional services to enhance your home's interior. This includes custom cabinetry, built-in shelving, and trim work. Our comprehensive approach ensures that every detail is executed to perfection, creating a cohesive and polished look throughout your home.",
  "Customers satisfaction is our top priority. We strive to exceed your expectations by delivering exceptional quality, clear communication, and reliable service. Our team is dedicated to creating spaces that enhance your comfort and enjoyment, making your home a true reflection of your personal style."
];


const KitchenCarousel = () => {
  return <GenericCarousel slides={kitchenSlides} title="Kitchen Additions, Kitchen Remodels, and Fireplaces" text={kitchenText} />;
};

export default KitchenCarousel;
