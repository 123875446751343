import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './rscPages/HomePage';
import EstimatePage from './rscPages/EstimatePage';
import SeeOurWorkPage from './rscPages/SeeOurWorkPage';
import Navbar from './rscComponents/common/Navbar';
import ContactUs from './rscComponents/common/ContactUsBlurb';
import { ModalContext } from './rscComponents/common/ModelContext'; // Import ModalContext
import './App.css';
import ReactGA from 'react-ga4';
import ScrollToTop from './rscComponents/common/ScrollToTop';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // Replace with your actual ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const { openModal } = useContext(ModalContext);

    // Track page views on component mount
    React.useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component */}
      <div className="App">
        <Navbar
          customClass="your-navbar-class"
          hideContact={false}
          hideSeeOurWork={false}
          hideQuote={false}
          onContactClick={openModal} // Use openModal from context
        />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/estimate-form" element={<EstimatePage />} />
          <Route path="/see-our-work" element={<SeeOurWorkPage />} />
        </Routes>
        <ContactUs />
      </div>
    </Router>
  );
};

export default App;
