import React from 'react';
import './SOWButtonLinks.css';

const ButtonLinks = () => {
  const buttons = [
    { label: 'Bathroom Remodels', section: 'room-ren-other-section' },
    { label: 'Brick Work', section: 'exterior-section' },
    { label: 'Closets', section: 'basement-section' },
    { label: 'Decks', section: 'framing-section' },
    { label: 'Drop Ceilings', section: 'basement-section' },
    { label: 'Exterior Finishing', section: 'exterior-section' },
    { label: 'Fence Installs', section: 'framing-section' },
    { label: 'Finished Basements', section: 'basement-section' },
    { label: 'Fireplaces', section: 'kitchen-section' },
    { label: 'Floor Tile Installs', section: 'flooring-tile-section' },
    { label: 'Flooring Installs', section: 'flooring-tile-section' },
    { label: 'Kitchen Additions', section: 'kitchen-section' },
    { label: 'Kitchen Remodels', section: 'kitchen-section' },
    { label: 'Patio Additions', section: 'patio-section' },
    { label: 'Room Renovations', section: 'room-ren-other-section' },
    { label: 'Rough Framing', section: 'framing-section' },
    { label: 'Shed Builds', section: 'shed-section' },
    { label: 'Shower Remodeling', section: 'shower-tile-section' },
    { label: 'Shower Tiling', section: 'shower-tile-section' },
    { label: 'Stone Work', section: 'patio-section' },
  ];

  // Sort buttons alphabetically by label
  buttons.sort((a, b) => a.label.localeCompare(b.label));

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="sow-button-links">
      {buttons.map((button) => (
        <button key={button.label} onClick={() => handleScroll(button.section)}>
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default ButtonLinks;
