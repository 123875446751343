// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const basementSlides = [
  { 
    src: '/rscAssets/SOWAssets/Basement/barnDoor1.webp', 
    type: 'image', 
    alt: 'finished basement with barn door install',
    loading: "lazy" 
  },
  { 
    src: '/rscAssets/SOWAssets/Basement/FinishedBasement.mp4', 
    type: 'video', 
    alt: 'finished basement with wet bar video'
  },
  { 
    src: '/rscAssets/SOWAssets/Basement/TrimDropC.mp4', 
    type: 'video', 
    alt: 'finished basement finished trim and drop ceilings video'
  },
  { 
    src: '/rscAssets/SOWAssets/Basement/ceilingWork1.webp', 
    type: 'image', 
    alt: 'drop ceiling with wood finish'
  },
  ...Array.from({ length: 3 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Basement/closet${i + 1}.webp`, 
    type: 'image',
    alt: `Closet finish with painted white shelves ${i + 1}`
  }))
];

const basementText = [
  "Transform your basement into a versatile living space with our finished basement services. Whether you're looking to create an additional family room, a home office, or a personal gym, we can help you maximize the potential of your basement. Our team designs and constructs finished basements that are functional, comfortable, and beautifully integrated with the rest of your home.",
  "We start with a thorough consultation to understand your vision and needs. From there, we develop a comprehensive plan that includes layout, lighting, insulation, and flooring. Our skilled professionals handle every aspect of the construction process, ensuring a seamless and high-quality finish that meets all building codes and standards.",
  "Drop ceilings offer a practical and aesthetically pleasing solution for covering unfinished ceilings, hiding ductwork, and improving acoustics. We provide a range of drop ceiling options, including acoustic tiles, decorative panels, and custom designs. Our installation services ensure a precise fit and a professional appearance, enhancing the look and functionality of your space.",
  "Our closet solutions are designed to maximize storage and organization. Whether you need a walk-in closet, reach-in closet, or custom wardrobe, we tailor our designs to fit your space and lifestyle. We offer a variety of materials, finishes, and accessories to create closets that are both stylish and highly functional.",
  "Beyond finished basements, drop ceilings, and closets, we offer a wide range of additional services to enhance your home. This includes everything from custom cabinetry and shelving to trim work and built-ins. Our expertise in carpentry and home improvement allows us to handle projects of any size and complexity with precision and care.",
  "Each project begins with a personalized consultation where we discuss your ideas and requirements. Our goal is to provide solutions that meet your needs and exceed your expectations. We work closely with you throughout the process, keeping you informed and involved in every decision.",
  "Quality and craftsmanship are at the core of our services. We use high-quality materials and employ skilled professionals to ensure that every project is completed to the highest standards. Our commitment to excellence means you can trust us to deliver results that enhance the beauty, functionality, and value of your home.",
  "Customer satisfaction is our top priority. We strive to create a positive experience by providing clear communication, reliable service, and exceptional results. Our team is always available to address any questions or concerns, ensuring a smooth and enjoyable process from start to finish.",
  "Let us help you transform your home with our comprehensive services. Whether you're looking to finish your basement, install a drop ceiling, or create a custom closet, we have the skills and experience to bring your vision to life. Contact us today to get started on your next home improvement project."
];


const BasementCarousel = () => {
  return <GenericCarousel slides={basementSlides} title="Finished Basements, Drop Ceilings, Closets, and More" text={basementText} />;
};

export default BasementCarousel;
