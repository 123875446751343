// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const exteriorSlides = [
  ...Array.from({ length: 9 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Exterior/exteriorWork${i + 1}.webp`, 
    type: 'image',
    alt: `exterior finishing with hardie board or brick and stone work ${i + 1}`
  })),
  { 
    src: '/rscAssets/SOWAssets/Exterior/exteriorFinishing.mp4', 
    type: 'video', 
    alt: 'exterior finishing work with hardie siding video',
    loading: "lazy" 
  }
];

const exteriorText = [
  "Our exterior finishing services are designed to enhance the curb appeal and durability of your home. From siding and painting to window and door installation, we offer a comprehensive range of solutions to ensure your home looks beautiful and is well-protected against the elements. Our team of experts uses high-quality materials and meticulous craftsmanship to deliver exceptional results.",
  "Siding is one of the most important aspects of your home's exterior. We provide a variety of siding options, including vinyl, wood, fiber cement, and more, each offering unique benefits and aesthetic appeal. Our installation process ensures a precise fit and a seamless finish, enhancing your home's insulation and energy efficiency.",
  "A fresh coat of paint can transform the look of your home and protect it from weathering. Our exterior painting services include thorough surface preparation, high-quality paints, and professional application techniques. We offer a wide range of colors and finishes to match your style and ensure long-lasting beauty and protection.",
  "Windows and doors play a crucial role in your home's appearance, energy efficiency, and security. We offer a variety of window and door styles and materials to suit your needs and preferences. Our expert installation ensures proper sealing and operation, improving your home's comfort and value.",
  "Brick work adds a timeless and classic touch to your home's exterior. Whether you're looking to add a brick façade, build a brick wall, or install a brick patio, our skilled masons deliver exceptional craftsmanship. We offer a variety of brick styles and colors to match your home's design and ensure a durable, beautiful finish. Our brick installations not only enhance aesthetics but also provide additional strength and insulation to your home.",
  "In addition to siding, painting, windows, doors, and brick work, we provide a range of exterior finishing services to complete your home's look. This includes trim work, soffits, fascia, gutters, and more. Our attention to detail ensures a cohesive and polished appearance that enhances your home's overall aesthetics.",
  "Our team is committed to providing personalized service and exceptional quality. We work closely with you to understand your vision and requirements, offering expert advice and solutions to achieve the desired outcome. From initial consultation to final installation, we ensure a smooth and stress-free process.",
  "Durability and weather resistance are key considerations in our exterior finishing services. We use materials and techniques that withstand harsh weather conditions and require minimal maintenance, ensuring your home remains beautiful and protected for years to come.",
  "Customer satisfaction is our top priority. We strive to exceed your expectations by delivering high-quality workmanship, reliable service, and transparent communication. Our team is always available to address any questions or concerns, ensuring a positive experience from start to finish.",
  "Let us help you enhance your home's exterior with our comprehensive finishing services. Whether you're looking to update your siding, repaint your home, replace windows and doors, add brick work, or complete the finishing touches, we have the expertise and experience to bring your vision to life."
];

const ExteriorCarousel = () => {
  return <GenericCarousel slides={exteriorSlides} title="Exterior Finishing, Brick Work, and More" text={exteriorText} />;
};

export default ExteriorCarousel;
