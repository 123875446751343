import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const roomRenOtherSlides = [
  {
    src: `/rscAssets/SOWAssets/RoomRenAndOther/atticRoom1.webp`, 
    type: 'image', 
    alt: `room with vaulted ceilings and fancy beams image`,
    loading: "lazy"  
  },
  { 
    src: '/rscAssets/SOWAssets/RoomRenAndOther/RoomAddBath.mp4', 
    type: 'video', 
    alt: 'room with vaulted ceilings and fancy beams with finished bathroom video'
  },
  ...Array.from({ length: 16 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/RoomRenAndOther/bRemodel${i + 1}.webp`, 
    type: 'image',
    alt: `full bathroom remodel images ${i + 1}`
  }))
];

const roomRenOtherText = [
  "Our room renovation services are designed to breathe new life into any space in your home. Whether you're looking to update a single room or undertake a comprehensive remodel, our team provides tailored solutions that meet your needs and exceed your expectations. From modernizing outdated interiors to creating entirely new layouts, we handle every aspect of the renovation process with care and precision.",
  "Bathroom remodels are one of our specialties, transforming your bathroom into a luxurious and functional retreat. We offer a full range of services, including custom cabinetry, tile work, lighting, and fixture installations. Our designs combine style and functionality, ensuring that your new bathroom is both beautiful and practical.",
  "Each bathroom remodel starts with a detailed consultation to understand your vision and preferences. Our team works with you to create a design that maximizes space, improves layout, and incorporates the latest trends and technologies. From sleek, contemporary designs to timeless, classic styles, we tailor our services to suit your taste and lifestyle.",
  "Our room renovations cover a wide variety of spaces, including living rooms, bedrooms, dining rooms, and home offices. We provide custom solutions that enhance the aesthetics and functionality of each room. Whether it's installing new flooring, updating wall finishes, or adding custom built-ins, our team ensures a seamless and high-quality finish.",
  "For homeowners looking to create an open-concept layout, we offer services to remove walls and reconfigure spaces. Our structural expertise ensures that your home remains safe and sound while achieving the open, airy feel you desire. We handle everything from obtaining permits to final touches, providing a stress-free renovation experience.",
  "In addition to room renovations and bathroom remodels, we offer a range of additional services to enhance your home. This includes custom cabinetry, trim work, lighting installations, and more. Our comprehensive approach ensures that every detail is executed to perfection, creating a cohesive and polished look throughout your home.",
  "Our team is committed to delivering exceptional quality and craftsmanship. We use high-quality materials and employ skilled professionals to ensure that every project is completed to the highest standards. Our attention to detail and dedication to excellence means you can trust us to deliver results that enhance your home's beauty and functionality.",
  "Customer satisfaction is our top priority. We strive to create a positive experience by providing clear communication, reliable service, and exceptional results. Our team is always available to address any questions or concerns, ensuring a smooth and enjoyable process from start to finish.",
  "Let us help you transform your home with our comprehensive room renovations, bathroom remodels, and more. Whether you're looking to update a single room or undertake a complete home transformation, we have the expertise and experience to bring your vision to life. Contact us today to get started on your next renovation project."
];


const RoomRenAndOther = () => {
  return <GenericCarousel slides={roomRenOtherSlides} title="Room Renovations, Bathroom Remodels, and More" text={roomRenOtherText} />;
};

export default RoomRenAndOther;
