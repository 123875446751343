// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const flooringTileSlides = [
  ...Array.from({ length: 4 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/FlooringAndTile/flooring${i + 1}.webp`, 
    type: 'image',
    alt: `finished hardwood floor install image ${i + 1}`,
    loading: "lazy" 
  })),
  ...Array.from({ length: 3 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/FlooringAndTile/tileWork${i + 1}.webp`, 
    type: 'image',
    alt: `finished floor tile image ${i + 1}`
  }))
];

const flooringTileText = [
  "Our flooring and floor tile installs bring a new level of elegance and durability to your home. Whether you're looking to revamp a single room or transform your entire home, we offer a wide range of flooring solutions to meet your needs. From classic hardwood to modern tile, we have something for every style and budget.",
  "Our expert team specializes in the installation of various types of flooring, including hardwood, laminate, vinyl, and tile. Each option is carefully selected to ensure high-quality performance and aesthetic appeal. Our commitment to excellence means you can trust us to deliver flawless installations that stand the test of time.",
  "With hardwood flooring, you can enjoy the timeless beauty and warmth of natural wood. We offer a variety of species, finishes, and plank sizes to match your décor and personal taste. Our professionals handle every step of the installation process, from subfloor preparation to finishing touches, ensuring a seamless and durable result.",
  "Laminate flooring is an excellent choice for those seeking the look of real wood or stone without the high cost. It's a versatile and durable option that resists scratches, stains, and fading, making it ideal for high-traffic areas. Our team ensures precise installation for a polished and long-lasting finish.",
  "Vinyl flooring combines style and functionality with its wide range of designs and excellent water resistance. Perfect for kitchens, bathrooms, and basements, vinyl flooring is easy to maintain and offers exceptional durability. We offer both sheet vinyl and luxury vinyl planks, installed with meticulous attention to detail.",
  "Tile flooring adds a touch of sophistication to any space with its endless design possibilities. From ceramic to porcelain to natural stone tiles, we provide expert installation services that ensure a perfect fit and finish. Our tiling services include custom layouts, inlays, and grout color matching to create a cohesive and stunning look.",
  "In addition to our comprehensive flooring installation services, we also offer repairs and maintenance to keep your floors looking their best. Whether it's refinishing hardwood, replacing damaged tiles, or regrouting, our team is equipped to handle all your flooring needs.",
  "We understand that choosing the right flooring is a significant decision. That's why we offer personalized consultations to help you explore your options and make informed choices. Our goal is to provide you with beautiful, durable floors that enhance the comfort and value of your home.",
  "Safety and sustainability are important to us. We use eco-friendly materials and practices whenever possible, ensuring that your new floors are not only beautiful but also environmentally responsible. Trust us to deliver exceptional flooring solutions that combine luxury, practicality, and sustainability."
];


const FlooringAndTileCarousel = () => {
  return <GenericCarousel slides={flooringTileSlides} title="Flooring and Floor Tile Installs" text={flooringTileText} />;
};

export default FlooringAndTileCarousel;
