// src/rscComponents/common/ContactUsBlurb.js
import React, { useContext } from 'react';
import Modal from './Modal';
import { ModalContext } from './ModelContext';
import './ContactUsBlurb.css';
import './Modal.css';

const ContactUs = () => {
  const { isModalOpen, closeModal } = useContext(ModalContext);

  return (
    <Modal show={isModalOpen} onClose={closeModal}>
      <div id="contact-us" className="contact-us-section">
        <video className="background-video" preload="auto" autoPlay loop muted playsInline loading="lazy">
          <source src="/rscAssets/HomeAssets/contactUsVideo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="contact-container">
          <h2>Contact Us</h2>
          <p><i className="fas fa-map-marker-alt"></i> We operate locally in St. Charles County and St. Louis County, MO</p>
          <p><i className="fas fa-envelope"></i> Email: <a href="mailto:info@rstoneconstructs.com">info@rstoneconstructs.com</a></p>
          <p><i className="fas fa-phone"></i> Phone: <a href="tel:314-9527-339">314-952-7339</a></p>
          <p><i className="fas fa-clock"></i> Office Hours: By Appointment Only</p>
        </div>
      </div>
    </Modal>
  );
};

export default ContactUs;
