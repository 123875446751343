// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const shedSlides = [
  ...Array.from({ length: 24 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Shed/shed${i + 1}.webp`, 
    type: 'image',
    alt: `shed build image ${i + 1}`
  }))
];

const shedText = [
  "Our shed building services offer a perfect solution for expanding your storage space, creating a workshop, or adding a stylish garden feature. We design and construct custom sheds that cater to your specific needs and preferences. From small garden sheds to large storage units, our team ensures that each project is built to last with high-quality materials and craftsmanship.",
  "Every shed project begins with a thorough consultation to understand your requirements and vision. Whether you need extra storage for gardening tools, a dedicated space for hobbies, or a multipurpose unit, we tailor the design to suit your needs. Our expert designers create detailed plans that maximize space, functionality, and aesthetics.",
  "We offer a variety of shed styles, including traditional, modern, and rustic designs. You can choose from different materials such as wood, metal, and composite, each offering unique benefits and appearances. Our goal is to construct a shed that not only meets your practical needs but also enhances the overall look of your property.",
  "Our construction process ensures durability and quality. We use high-grade materials and proven building techniques to create sheds that can withstand the elements and provide long-lasting performance. From the foundation to the roof, every detail is carefully handled to ensure structural integrity and reliability.",
  "Customization is a key aspect of our shed building services. We offer a range of options for doors, windows, roofing, and interior features. Whether you need shelving, workbenches, or insulation, we can incorporate these elements into your shed to create a truly personalized space. Our team is dedicated to delivering a product that exceeds your expectations.",
  "In addition to new shed constructions, we also provide renovation and repair services. If you have an existing shed that needs an upgrade or repair, we can help restore it to its former glory. Our team can handle everything from replacing damaged materials to adding new features, ensuring your shed remains functional and attractive.",
  "Our commitment to customer satisfaction drives us to deliver exceptional service and quality. We work closely with you throughout the project, keeping you informed and involved in every decision. Our transparent process ensures that you are always aware of the project's progress and timeline.",
  "Safety and compliance are paramount in our shed building projects. We adhere to all local building codes and regulations, ensuring that your new shed is safe and legally compliant. Our team conducts thorough inspections and quality checks at every stage of the construction process.",
  "Let us help you create the perfect shed for your needs. Our expertise and dedication to quality make us the ideal partner for your shed building project. Whether you need a simple storage solution or a custom-designed workshop, we have the skills and experience to bring your vision to life."
];


const ShedCarousel = () => {
  return <GenericCarousel slides={shedSlides} title="Shed Building" text={shedText} />;
};

export default ShedCarousel;
