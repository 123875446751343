import React from 'react';
import { Link } from 'react-router-dom';
import ImageCarousel from '../rscComponents/HomeComponents/ImageCarousel';
import Footer from '../rscComponents/common/Footer'; // Import the Footer component
import './HomePage.css'; //asd

const HomePage = () => {
  return (
    <div className="page-wrapper">
      
      <div className="content-wrapper">
        <section>
          <div id="homepageHero" className="hero-container">
            <video className="hero-video" preload="auto" autoPlay loop muted playsInline loading="lazy">
              <source src="/rscAssets/HomeAssets/backgroundVideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="hero-content">
              <div className="hero-box">
                <h2>RavenStone Construction LLC.</h2>
                <h1>We build more than just structures; we create spaces where memories are made and dreams come true.</h1>
                <h3>Serving St. Charles & St. Louis Counties</h3>
                <p>Please click Free Estimates to submit a request for your new dream!</p>
                <Link to="/estimate-form">
                  <button className="quote-button">Free Estimates</button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section>
          <ImageCarousel />
        </section>

        <section>
          <div className="quality-efficiency-section">
            <h2 className="section-header">At RavenStone Construction LLC, we deliver quality results in an efficient manner!</h2>
            <div className="columns">
              <div className="column">
                <div className="tile">
                  <img src="/rscAssets/HomeAssets/bestQualityImage.svg" alt="Quality Craftsmanship" className="quality-efficiency-pics" loading="lazy"/>
                  <h3>Quality Craftsmanship</h3>
                  <p>We are committed to delivering the highest quality of work. We use the best materials and the best techniques to ensure every project meets our stringent standards.</p>
                </div>
              </div>
              <div className="column">
                <div className="tile">
                  <img src="/rscAssets/HomeAssets/bestEfficiencyImage.svg" alt="Efficiency" className="quality-efficiency-pics" loading="lazy"/>
                  <h3>Efficiency</h3>
                  <p>We value your time and strive to complete projects on schedule without sacrificing quality. Our processes and experience enable us to work efficiently and effectively.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section>
          <div className="home-improvement-kitch-bath-section">
            <div className="home-improvement-kitch-bath-columns">
              <div className="home-improvement-kitch-bath-column">
                <h2 className="home-improvement-kitch-bath-section-header">Thinking or Planning Your Next Home Improvement Project?</h2>
                <div className="home-improvement-kitch-bath-tile">
                  <img src="/rscAssets/HomeAssets/homeImprovementPicture.jpg" alt="Quality Craftsmanship" className="home-improvement-kitch-bath-pic" loading="lazy"/>
                  <h3>RavenStone Construction Brings Everything You Need to Complete the Project</h3>
                  <p>Let's be real: everyone has that favorite spot in their home, be it the kitchen where delicious aromas fill the air or the bathroom that offers a serene escape. At Midwest Construction LLC, we understand the essence of a quality home. Our expertise ensures excellent craftsmanship for every corner of your home.</p>
                
                  <div>
                    <h3>Why Choose RavenStone Construction?</h3>
                    <p>Expert Craftsmanship: With years of experience in the industry, our team of skilled professionals brings unparalleled expertise to every project. We pride ourselves on our attention to detail and commitment to quality.</p>
                    <p>Customized Solutions: Every home is unique, and so are your needs. We offer customized solutions tailored to fit your specific requirements, ensuring that your home reflects your personality and lifestyle.</p>
                    <p>Comprehensive Services: From initial consultation to final inspection, we handle every aspect of your project. Our services include design, construction, remodeling, and finishing touches. Whether it's a kitchen renovation, bathroom upgrade, or an entire home makeover, we've got you covered.</p>
                  </div>

                  <div>
                    <h3>Our Process</h3>
                    <p>Consultation: We begin with a thorough consultation to understand your vision, preferences, and budget. This helps us create a detailed plan that aligns with your goals.</p>
                    <p>Construction: Our skilled craftsmen bring the design to life, using high-quality materials and the latest construction techniques. We ensure that every aspect of the project meets our rigorous standards of excellence.</p>
                    <p>Finishing Touches: We pay close attention to the finishing touches, ensuring that every detail is perfect. From cabinetry to lighting, we ensure that every element complements your overall design.</p>
                  </div>

                  <div>
                    <h3>Our Promise</h3>
                    <p>At RavenStone Construction, we are committed to delivering superior results that exceed your expectations. Our goal is to create a space that you'll love for years to come. Trust us with your home improvement project, and let us bring your vision to life with professionalism and care.</p>
                  </div>

                  <p>Ready to transform your home? Contact RavenStone Construction LLC today for a free consultation and let us help you create the home of your dreams.</p>
                  <div className="text-container-button">
                    <Link to="/estimate-form">
                      <button className="quote-button">Free Estimates</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="home-improvement-kitch-bath-column">
                <h2 className="home-improvement-kitch-bath-section-header">Needing a Kitchen or Bathroom That Needs a Remodel?</h2>
                <div className="home-improvement-kitch-bath-tile">
                  <img src="/rscAssets/HomeAssets/kitchenRemodelDesign.jpeg" alt="Quality Craftsmanship" className="home-improvement-kitch-bath-pic" loading="lazy"/>
                  <h3>RavenStone Construction Sees Your Vision and Applies the Perfect Touch to Make Your Dreams Come True</h3>

                  <div>
                    <p>We excel in high-quality construction coupled with contemporary color palettes. Whether you dream of a vibrant red kitchen with custom cabinets and sleek appliances, or a tranquil bathroom adorned with soft peach tiles, we offer a wide range of choices to suit your taste.</p>

                  </div>

                  <div>
                    <h3>Custom Kitchens</h3>
                    <p>Your kitchen is more than just a place to cook; it's the heart of your home. Our custom kitchen designs blend functionality with style, ensuring every detail is tailored to your needs. From state-of-the-art appliances to exquisite cabinetry, we create spaces that inspire culinary creativity and bring families together.</p>
                  </div>

                  <div>
                    <h3>Luxury Bathrooms</h3>
                    <p>Transform your bathroom into a sanctuary of relaxation and luxury. With our expert construction team, we can create serene environments that offer a perfect escape from the hustle and bustle of everyday life. Imagine soaking in a tub surrounded by calming hues and high-end finishes, or stepping into a shower that feels like a spa retreat.</p>
                  </div>

                  <div>
                    <h3>Attention to Detail</h3>
                    <p>We believe that the smallest details can make the biggest impact. From the selection of tiles and countertops to the choice of lighting and fixtures, we ensure every element is meticulously planned and executed to perfection.</p>
                  </div>

                  <div>
                    <h3>Sustainable Choices</h3>
                    <p>Our commitment to sustainability means we also offer eco-friendly materials and energy-efficient solutions. You can have a beautiful home that is kind to the environment.</p>
                  </div>

                  <p>At RavenStone Construction, we don't just build spaces; we craft experiences. Let us help you create a home that reflects your unique style and meets your every need.</p>
                
                  <div className="text-container-button">
                    <Link to="/estimate-form">
                      <button className="quote-button" >Free Estimates</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="transform-improvement-section">
            <div className="transform-improvement-columns">
              <div className="transform-improvement-column">
                <h2 className="transform-improvement-section-header">Needing to Transform Your Basement, Deck, or Shed?</h2>
                <div className="transform-improvement-tile">
                  <img src="/rscAssets/HomeAssets/deckAdditionSection.jpg" alt="Quality Craftsmanship" className="transform-improvement-pic" loading="lazy"/>
                  <h3>Transform Your Home with RavenStone Construction: Where Dreams Become Reality</h3>

                  <div>
                    <p>Before starting any project, RavenStone Construction LLC meticulously plans to ensure every detail is accounted for. Our process includes extensive meetings to finalize project specifics with client sign-off before construction begins. We take pride in not cutting corners, ensuring that every aspect of your project is executed to the highest standards.</p>
                  </div>

                  <div>
                    <h3>Basement Finishing and Renovations</h3>
                    <p>For those dreaming of basement renovations, we specialize in custom shelving and creating new spaces with fresh carpet. Your entertainment area will feel complete with a built-in home theater, transforming your basement into the ultimate relaxation and entertainment hub. The handyman in the family will appreciate our custom-built workshops designed for efficiency and productivity, tailored to your unique needs and preferences.</p>
                  </div>

                  <div>
                    <h3>Custom Decks and Sheds</h3>
                    <p>We also excel in building custom decks and sheds that enhance your outdoor living space. Whether you envision a cozy deck for summer barbecues or a sturdy shed for all your storage needs, RavenStone Construction LLC delivers top-quality craftsmanship tailored to your preferences. Our decks are designed to blend seamlessly with your home’s architecture, providing a perfect spot for family gatherings, outdoor dining, or simply unwinding after a long day.</p>
                    <p>Our sheds are built with durability in mind, offering secure and stylish storage solutions for your tools, equipment, and seasonal items. Each shed is constructed using high-quality materials to withstand the elements and keep your belongings safe year-round.</p>
                  </div>

                  <div>
                    <h3>Commitment to Quality and Customer Satisfaction</h3>
                    <p>Our commitment to quality and customer satisfaction means we work closely with you at every stage, from initial consultation to final inspection. With RavenStone Construction LLC, you can trust that your project will be handled with the utmost care and professionalism, resulting in a finished product that exceeds your expectations.</p>
                  </div>

                  <p>Contact us today to discuss your home improvement needs and discover how we can help turn your vision into reality. At RavenStone Construction LLC, we build more than just structures; we create spaces where memories are made and dreams come true.</p>
                  <div className="text-container-button">
                    <Link to="/estimate-form">
                      <button className="quote-button">Free Estimates</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section>
        <Footer /> {/* Add the Footer component here */}
      </section>
      
    </div>
  );
};

export default HomePage;
