// ShowerCarousel.js
import React from 'react';
import GenericCarousel from '../common/GenericCarousel';

const framingSlides = [
  ...Array.from({ length: 6 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Framing/framing${i + 1}.webp`, 
    type: 'image',
    alt: `rough framing and wall standing and zip system plywood ${i + 1}`
  })),
  { 
    src: '/rscAssets/SOWAssets/Framing/fenceWork.mp4', 
    type: 'video', 
    alt: 'trashcan fence build video',
    loading: "lazy" 
  },
  {
    src: '/rscAssets/SOWAssets/Framing/roughFraming.mp4', 
    type: 'video', 
    alt: 'subfloor install for house video'
  },
  ...Array.from({ length: 4 }, (_, i) => ({ 
    src: `/rscAssets/SOWAssets/Framing/deckWork${i + 1}.webp`, 
    type: 'image',
    alt: `short deck install image ${i + 1}`
  }))
];

const framingText = [
  "Our rough framing services provide the essential foundation for any construction project, ensuring structural integrity and precision. From residential homes to commercial buildings, our skilled carpenters handle all aspects of framing with meticulous attention to detail. We use high-quality materials and industry-standard techniques to deliver durable and reliable framing solutions.",
  "Whether it's a new construction or a renovation, our team ensures that every project starts off on the right foot. We collaborate closely with architects, engineers, and contractors to create a solid framework that meets all building codes and specifications. Our expertise includes wall framing, roof framing, floor joists, and more, guaranteeing a robust structure that stands the test of time.",
  "Decks are a wonderful addition to any home, providing an outdoor space for relaxation, entertainment, and enjoyment. Our custom deck building services offer a range of styles and materials to match your aesthetic preferences and functional needs. From classic wood to low-maintenance composite decking, we create beautiful and durable decks that enhance your outdoor living experience.",
  "Each deck project begins with a consultation to understand your vision and requirements. Our designers work with you to create a layout that maximizes space and complements your home's architecture. We handle everything from obtaining necessary permits to the final installation, ensuring a seamless and stress-free process.",
  "Beyond standard decks, we also specialize in unique features such as multi-level decks, built-in seating, pergolas, and outdoor kitchens. These additions not only increase the usability of your deck but also add value to your property. Our goal is to create a custom outdoor space that suits your lifestyle and enhances your home's curb appeal.",
  "In addition to rough framing and decks, we offer a wide range of carpentry services to meet all your construction needs. This includes everything from custom cabinetry and trim work to installing doors and windows. Our experienced team is equipped to handle projects of any size and complexity with professionalism and craftsmanship.",
  "Safety and quality are our top priorities in every project we undertake. We adhere to the highest standards of construction practices and ensure all work is inspected and meets regulatory requirements. Our commitment to excellence means you can trust us to deliver results that are not only aesthetically pleasing but also structurally sound.",
  "Customer satisfaction is at the heart of our business. We strive to exceed your expectations by providing personalized service, clear communication, and a dedication to quality workmanship. Our team is always available to address any questions or concerns, ensuring a positive experience from start to finish.",
  "From initial planning to final execution, we are committed to delivering exceptional results that enhance your home and improve your quality of life. Let us help you bring your construction and renovation ideas to life with our comprehensive and reliable services."
];


const FramingCarousel = () => {
  return <GenericCarousel slides={framingSlides} title="Rough Framing, Decks, and More" text={framingText} />;
};

export default FramingCarousel;
