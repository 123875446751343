import React from 'react';
import EstimateForm from '../rscComponents/EstimateComponents/EstimateForm';
import './EstimatePage.css';
import Footer from '../rscComponents/common/Footer';


const EstimatePage = () => (
  <div className="estimate-page">
    <div className="content">
      <EstimateForm />
    </div>
    <Footer customFooterClass="estimate-footer"/>
  </div>
);

export default EstimatePage;
